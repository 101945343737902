<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Role
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>

                  <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        dark
                        :ripple="false"
                        height="43"
                        class="
                          font-weight-bold
                          text-capitalize
                          ms-auto
                          btn-primary
                          bg-success
                          py-3
                          px-6
                          ms-3
                        "
                        >New Role</v-btn
                      >
                    </template>
                    <v-card class="card-shadow border-radius-xl">
                      <div class="card-header-padding card-border-bottom">
                        <span class="font-weight-bold text-h5 text-typo mb-0">{{
                          formTitle
                        }}</span>
                      </div>
                      <v-card-text class="card-padding">
                        <v-form ref="frm">
                          <v-container class="px-0">
                            <v-row>
                              <v-col cols="12">
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                  >Role
                                </label>

                                <v-text-field
                                  v-model="editedItem.role"
                                  hide-details="auto"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    input-icon
                                    mt-2
                                  "
                                  dense
                                  flat
                                  filled
                                  solo
                                  height="43"
                                  placeholder="Enter Role"
                                  :rules="[(v) => !!v || 'Role is required']"
                                  persistent-hint
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <!-- <v-row>
                              <v-col>
                                <label
                                  class="
                                    text-md text-typo
                                    font-weight-bolder
                                    ms-1
                                  "
                                  >App module
                                </label>
                                <v-select
                                  v-model="editedItem.app_module"
                                  :items="app_module"
                                  return-object
                                  item-text="app_module_name"
                                  item-value="id"
                                  placeholder="Select module(s)"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    select-style
                                    mt-3
                                  "
                                  outlined
                                  chips
                                  multiple
                                  single-line
                                  height="46"
                                >
                                  <template v-slot:selection="{ item, index }">
                                    <v-chip
                                      label
                                      v-if="index === 0"
                                      color="bg-default"
                                      class="py-1 px-2 my-0"
                                    >
                                      <span
                                        class="text-white text-caption ls-0"
                                        >{{ item.app_module_name }}</span
                                      >
                                    </v-chip>
                                    <span
                                      v-if="index === 1"
                                      class="grey--text text-caption"
                                    >
                                      (+{{ editedItem.app_module.length - 1 }}
                                      others)
                                    </span>
                                  </template>

                                  <template v-slot:prepend-item>
                                    <v-list-item
                                      ripple
                                      @mousedown.prevent
                                      @click="toggle"
                                    >
                                      <v-list-item-action>
                                        <v-icon
                                          :color="
                                            editedItem.app_module.length > 0
                                              ? 'indigo darken-4'
                                              : ''
                                          "
                                        >
                                          {{ icon }}
                                        </v-icon>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Select All
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                  </template>
                                </v-select>
                              </v-col>
                            </v-row> -->
                          </v-container>
                        </v-form>
                      </v-card-text>

                      <v-card-actions class="card-padding d-flex justify-end">
                        <v-btn
                          @click="close"
                          elevation="0"
                          :ripple="false"
                          height="43"
                          class="
                            font-weight-bold
                            text-capitalize
                            btn-ls btn-secondary
                            bg-light
                            py-3
                            px-6
                          "
                          >Cancel</v-btn
                        >

                        <v-btn
                          @click="save"
                          elevation="0"
                          :ripple="false"
                          height="43"
                          dark
                          class="
                            text-capitalize
                            btn-ls btn-primary
                            bg-success
                            py-3
                            px-6
                          "
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>

              <v-card-text class="px-0 py-0">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :search="search"
                  class="table"
                  :page.sync="page"
                  hide-default-footer
                  @page-count="pageCount = $event"
                  :items-per-page="itemsPerPage"
                  mobile-breakpoint="0"
                >
                  <template v-slot:top>
                    <v-toolbar flat height="80">
                      <v-row>
                        <v-col cols="12" md="5">
                          <v-text-field
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            v-model="search"
                            placeholder="Search"
                          >
                            <template slot="prepend-inner">
                              <v-icon color="#adb5bd" size=".875rem"
                                >fas fa-search</v-icon
                              >
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card
                          class="card-shadow card-padding border-radius-xl"
                        >
                          <v-card-title
                            class="pt-0 text-h5 text-typo justify-center"
                            >Are you sure you want to delete this
                            item?</v-card-title
                          >
                          <v-card-actions class="pb-0">
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="closeDelete"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                                font-weight-bold
                                text-capitalize
                                btn-ls
                                bg-light
                                py-3
                                px-6
                              "
                              >Cancel</v-btn
                            >

                            <v-btn
                              @click="deleteItemConfirm"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                                font-weight-bold
                                text-capitalize
                                btn-ls btn-primary
                                bg-success
                                py-3
                                px-6
                              "
                              >Ok</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="my-2">
                      Modules
                      <br />
                      <v-chip
                        class="ma-2"
                        v-for="modules in item.app_module"
                        :key="modules.id"
                      >
                        <v-col cols="1">
                          {{ modules.app_module_name }}
                        </v-col>
                      </v-chip>
                    </td>
                    <br />
                  </template>
                  <!-- [`item.actions`]="{ item }" -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <!-- <span> Example test {{ item.district }}</span> -->
                    <v-btn
                      @click="editItem(item)"
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class=""
                      color="green"
                      >Edit
                    </v-btn>

                    <v-btn
                      @click="deleteItem(item)"
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class="ml-5"
                      color="#FF0000"
                    >
                      delete
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions class="card-padding">
                <v-row>
                  <v-col cols="6" lg="4" class="d-flex align-center">
                    <span class="text-body me-3 text-sm">Items per page:</span>
                    <v-select
                      hide-details
                      :items="paginationLimits"
                      item-text="label"
                      item-value="id"
                      outlined
                      background-color="rgba(255,255,255,.9)"
                      color="rgba(0,0,0,.6)"
                      light
                      v-model="itemsPerPage"
                      placeholder="Items per page"
                      class="
                        font-size-input
                        placeholder-lighter
                        text-color-light
                        input-alternative input-focused-alternative input-icon
                      "
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6" class="ml-auto d-flex justify-end">
                    <v-pagination
                      prev-icon="fa fa-angle-left"
                      next-icon="fa fa-angle-right"
                      class="pagination"
                      color="#38bd34"
                      v-model="page"
                      :length="pageCount"
                      circle
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import axios from "axios";
import jwt_decode from "jwt-decode";

import api from "../api";
import { mapGetters } from "vuex";
import { paginationLimits } from "../../../../global-data/paginationLimits";

export default {
  name: "paginated-tables",
  data() {
    return {
      userToken: localStorage.getItem("token_pnb"),

      page: 1,
      pageCount: 0,
      paginationLimits: paginationLimits,
      itemsPerPage: 10,
      page: 0,

      dialog: false,
      dialogDelete: false,
      items: [],
      app_module: [],
      search: "",
      editedIndex: -1,
      editedItem: {
        role: "",
        app_module: [],
        id: 0,
      },
      defaultItem: {
        role: "",
        app_module: [],
        id: 0,
      },
      headers: [
        {
          text: "Role",
          width: "300",
          sortable: true,
          value: "role",
        },
        // { text: "Module", value: "module", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    validate() {
      return this.$refs.frm.validate();
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },

    async initialize() {
      try {
        this.items = await api.get();
        this.app_module = await api.getAppModule();
      } catch (err) {
        this.showErrorAlert(err);
      }
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      console.log(item);
      let decoded = jwt_decode(this.userToken);

      let userRole = decoded.role;

      let u = userRole.includes((el) => {
        return el.id == item.id;
      });

      if (u) {
        this.showErrorAlert("No Permission to Delete your own Role.");
      } else {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      }
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await api.delete(this.editedItem.id);
        this.items.splice(this.editedIndex, 1);
        this.showSuccessAlert(`Role Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    },

    close() {
      this.$refs.frm.reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.validate()) {
        try {
          let type = "Saved";
          if (this.editedIndex > -1) {
            type = "Updated";
            await api.edit(this.editedItem.id, this.editedItem);
            Object.assign(this.items[this.editedIndex], this.editedItem);
          } else {
            let item = await api.create(this.editedItem);
            this.items.push(item);
          }
          this.showSuccessAlert(`Role ${type}.`);
          this.close();
        } catch (err) {
          this.showErrorAlert(err.message);
        }
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.editedItem.app_module = [];
        } else {
          this.editedItem.app_module = this.app_module.slice();
        }
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    ...mapGetters({
      currentPage: "getCurrentPage",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Role  " : "Edit Role";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 1;
    },
    selectAll() {
      return this.editedItem.app_module.length === this.app_module.length;
    },
    selectSome() {
      return this.editedItem.app_module.length > 0 && !this.selectAll;
    },
    icon() {
      if (this.selectAll) return "ni-fat-remove text-lg";
      if (this.selectSome) return "ni-fat-delete";
      return "fa-square-o";
    },
  },
};
</script>
